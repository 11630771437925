@import (reference) "../_variables";

footer {
	background-color: @color-brand;

	a {
		color: white;
	}
}

.footer-domain {
	display: none;

	@media(min-width: @screen-tablet) {
		display: block;
	}
}

.footer-container {
	max-width: @max-width-hero;
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin: 0 auto;
	
	.cta {
		margin: 60px 30px 60px 0;
		width: 250px;
		text-align: center;
		padding:15px;
		font-size: 20px;
		
		@media (max-width: @screen-tablet) {
			display: none;
		}
	}
}

.links {
	display: flex;
	flex-direction: column;
	width:100%;

	@media (min-width: @screen-tablet) {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	
	@media (max-width: @screen-tablet) {
		width: 100%;
	}

	@section-vertical-spacing: @spacing-large 0;
	@section-horizontal-spacing: 0 @spacing-standard 0 @spacing-standard;
	.footer-section {
		text-align: center;
		padding: @section-vertical-spacing;
		margin: @section-horizontal-spacing;
		
		
		ul.wrap-items {
			@gap-size: 8px;
			@flex-row-offset: -3px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			@media (min-width: @screen-tablet) {
				justify-content: flex-start;

				&.state-grid {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(33px, 1fr));
					grid-auto-flow: column;
					gap: 20px;
					column-gap: 12px;
				}
			}

			list-style: none;
			margin: @spacing-paragraph 0 0 -@gap-size;
			padding: 0;
			
			> li {
				margin: @flex-row-offset 0 0 @gap-size;
				
				a {
					.font-body();
					color: @color-white;
					text-decoration: none;
					font-size: @font-size-small;
					
					&:visited {
						color: @color-white;
					}
				}
			}
		}
		
		ul.list-items {
			list-style: none;
			padding: 0;
			margin: 0;
			text-align: left;
			
			li {
				margin-bottom: 20px;
			}
			a {
				.font-body();
				color: @color-white;
				text-decoration: none;
				font-size: @font-size-small;
				
				&:visited {
					color: @color-white;
				}
			}
		}
		
		ul.list-items.ppc {
			@media (min-width: @screen-mobile) {
				display: flex;
				grid-column-gap: @spacing-paragraph;
			}
		}
		
		ul.list-items--suite {
			@media(max-width: @screen-mobile-max){
				display:flex;
				flex-wrap: wrap;
				
				li { flex: 1 1 50%}
			}
		}
		
		&:last-child {
			border-bottom: none;
		}

		&:nth-child(2) {
			border-right: none;
		}
	}
	
	.footer-header {
		color: @color-white;
		text-transform: none;
		font-size: 21px;
		font-weight: 500;
		margin-bottom: 30px;
		@media(min-width:@screen-laptop){
			font-size: 24px;
			text-align: left;
		}
	}
	
	@media(min-width: @screen-tablet) {
		.footer-section {
			border-bottom: none;
			max-width: 600px;
			margin: @section-vertical-spacing;
			padding: @section-horizontal-spacing;

			h3 {
				text-align: left;
			}
			
			ul.list-items {
				margin-top: @spacing-paragraph;
			}
			
			&.desktop-last {
				border-right: none;
				order: -1;
			}
			
			&.desktop-column ul {
				flex-direction: column;
				grid-auto-flow: row !important;
				text-align: left;
			}
			
			&.desktop-width-limit ul {
				max-width: 260px;
				justify-content: left;
			}
		}
	}
	
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.footer-section {
			max-width: 1000px;
		}
	}
}

.copyright {
	.font-body();
	max-width: @max-width-hero;
	margin: auto;
	
	font-size: @font-size-small - 1px;
	padding: 0 @spacing-standard 45px;

	@media (min-width: @screen-tablet) {
		padding-bottom: @spacing-large;
	}

	color: @color-white;
}