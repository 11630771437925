@import (reference) "_variables";
@import (reference) "../_functions";
@import (reference) "../mixins/_styleResets";
@import (reference) "../mixins/_icons";
@import (reference) "../global/components/top-breadcrumbs";

@import "../global/global-template";

@import "components/_topNav";
@import "components/_footer.less";
@import "components/_ctas.less";
@import "../global/components/_image-and-details.less";
@import "../global/components/_statsSection.less";

body {
	scroll-behavior: smooth;
}

main {
	overflow-wrap: break-word;
	padding-bottom:0;
	padding-top: 0;
}

.imageplugin--align-center {
	text-align: center;
}

#section---GACEAssessments + p + ul, #section---GACEExamDetails + p + ul {
	list-style: none;
	
	li::before {
		content: "\2022";
		color: @color-brand;
		font-weight: bold;
		display: inline-block;
		width: 25px;
		margin-left: -25px;
		font-size: 40px;
		position: relative;
		top: 4px;
	}
}

.main-content {
	margin-top: -90px;
}

section.white-background {
	background-color: @color-white;
}

section.light-background {
	background-color: @color-brand-light-bg;
}

header {
	padding-top: 105px;
	padding-bottom: 105px;
	@media(min-width:  @screen-tablet){
		padding-top: 125px;
		padding-bottom: 125px;
	}
}
header.hero {
	padding: 75px 0 ;
	@media (min-width: @screen-tablet) {
		background: url("/resources/img/71/smiling-showing-thumbs-up.png") right top/contain @color-brand-light-bg no-repeat;
		padding-top: 125px;
		padding-bottom: 125px;
		
		&.hero-monetized-longform {
		
		}
		
		&.hero-monetized-suite-level {
		
		}
		
		&.hero-nonmonetized {
			background: url("/resources/img/71/smiling-brunette-woman-yellow-shirt-holding-clipboard.png") right top/contain @color-brand-light-bg no-repeat;
			background-position-y: 45px;
		}

	}
	&.hero--no-bg, &.hero-monetized-longform,&.hero-nonmonetized {
		padding-bottom: 30px;
		@media (min-width: @screen-tablet) {
			padding-bottom: 75px;
		}
		.content{
			padding-top: 30px ;
			padding-bottom: 30px ;
		}
	}
	.hero__title-container {
		@media(min-width: @screen-tablet){
			max-width: 60%;
		}
	}
	&.hero--no-bg{
		background: @color-brand-light-bg;
		.hero__title-container {
			@media(min-width: @screen-tablet){
				max-width: none;
			}
		}
	}
	
	&.about-us-background {
		padding-top: 125px;
		
		@media (min-width: @screen-tablet) {
			background: url("/resources/img/71/portrait-of-young-teacher-sitting-near-laptop.png") right top/contain @color-brand-light-bg no-repeat;
			padding-bottom: 50px;
		}
	}
}
header.no-hero--header {
	@media(max-width: @screen-tablet-max){
		padding-top: 90px;
	}
}
.content--bg.content {
	background: @color-brand-light-bg;
	max-width: none;
}
.content--cta{
	padding: 120px 30px 40px;
	max-width: 1260px;
	margin-left: auto;
	margin-right: auto;
	.logo {
		@media(max-width: @screen-mobile-max){
			display: none;
		}
		margin-bottom: 5px;
	}
}

.immediate-help {
	.stats-section__cards {
		@media(min-width: @screen-tablet){
			flex-wrap: wrap;
		}
	}
	.image-and-detail__content{
		align-self: baseline;
	}
}

.gray-1-bleed.content {
	max-width: none;
	background-color: @color-gray-1;
}

.white-bleed {
	padding-top: 80px;
	padding-bottom: 80px;
	@media(min-width: @screen-laptop){
		padding-top: 120px;
		padding-bottom: 120px;
	}
	&:not(:first-child){
		margin-top: 80px;
		@media(min-width: @screen-laptop){
			margin-top: 80px;
		}
	}
	&:not(:last-child){
		margin-bottom: 80px;
		@media(min-width: @screen-laptop){
			margin-bottom: 80px;
		}
	}
	
	.content--cta {
		padding-top: 0;
		padding-bottom: 0;
	}
	.pill-button {
		margin-bottom: 0;
	}
}

// make anchor buttons behave same as normal button
/*a.btn.square:after {
	padding-top: 9px;
	line-height: 100%;
}*/

button, input[type=button], a.btn {
	font-size: @font-size-larger;
	color: white;
	text-transform: capitalize;
	background-color: @color-button;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	border: none;
	width: 300px;
	height: @spacing-large;
	text-align: center;
	vertical-align: center;
	position: relative;
	line-height: @line-height-pct;
	font-family: @font-family;

	&.pill-button {
		font-size: @font-size;
	}
	
	&:hover {
		background-color: @color-button-light;
	}

	&:active {
		background-color: @color-button-light;
		box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
	}

	&.next {
		padding-right: 65px;

		&:after {
			.material(@material-arrow-forward);
			width: 65px;
			text-align: center;
			position: absolute;
			background-color: @color-button-light;
			top: 0;
			bottom: 0;
			right: 0;
			display: block;
			padding-top: 21.5px;
			border-top-right-radius: 100%;
			border-bottom-right-radius: 100%;
			@media(min-width: @screen-tablet){
				right: -20px;
			}
		}

		&:active:after {
			box-shadow: inset -4px 4px 4px rgba(0, 0, 0, 0.25);
		}

		display: block;
		margin: @spacing-standard auto;
		
		@media(min-width: @screen-tablet) {
			margin: @spacing-large auto;
		}

		section.content &:first-child {
			margin-top: 0;
		}
	}

	&.square {
		display: inline-block;
		text-align: center;
		height: 35px;
		width: 35px;
		line-height: 35px;
		margin: 0;
		box-shadow: none;
		padding: 0;
		font-weight: @font-weight;
		
		&:after {
			.material(@material-chevron-forward);
			display: block;
			width: 35px;
		}
	}
	
	&.round {
		display: inline-block;
		text-align: center;
		height: 35px;
		width: 35px;
		margin: 0;
		box-shadow: none;
		padding: 0;
		border-radius: 20px;
		
		&:after {
			.material(@material-chevron-forward);
			display: block;
			width: 35px;
			font-size: 14px;
		}
	}

	margin: 15px 0;
}

ul.site-feature-cards {
	.plain-ul();
	display: grid;
	grid-column-gap: @spacing-standard;
	grid-row-gap: @spacing-standard;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	grid-template: auto / auto;
	
	@media (min-width: @screen-tablet) {
		grid-template: auto / repeat(auto-fit, minmax(120px, 1fr));
		margin-top: 50px;
	}
	
	li {
		box-shadow: 0 7px 0 -3px @color-brand, 0 10px 15px fade(@color-black, 15%);
		border-radius: 10px;
		padding: 45px 30px 30px;
		background-color: @color-white;
		
		figure img {
			height: 63px;
			color: @color-brand;
		}
		
		p, h3 {
			margin-top: 20px;
		}
	}
}

.pull-quote {
	p + & {
		margin-top: 30px;
	}

	.font-h1();
	max-width: 600px;
	line-height: @line-height-pct;
	font-size: @font-size-larger-not-huge;
	font-weight: @font-weight;
	color: @color-gray-4;
	font-style: italic;
	text-align: center;

	margin-left: auto;
	margin-right: auto;

	+ button.next {
		margin-top: @spacing-standard;
	}

	&.brand-quote {
		color: @color-brand;
	}
}

.make-breadcrumbs(@color-brand-light-bg);

.cta-divider {
	margin-top: @spacing-large;
	margin-bottom: @spacing-large;
	padding-top: @spacing-standard;
	padding-bottom: @spacing-standard;
	border-top: 1px solid @color-brand-light;
	border-bottom: 1px solid @color-brand-light;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> :not(:last-child) {
		margin: 0 0 @spacing-standard;
		max-width: ~"calc(min(100%, 700px))";
	}

	@media(min-width: @screen-tablet) {
		flex-direction: row;
		> :not(:last-child) {
			margin-bottom: 0;
			margin-right: @spacing-standard;
			max-width: ~"calc(min(70%, 700px))";
		}

		.pull-quote.brand-quote {
			text-align: left;
		}
	}
}

.full-width-cta {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	background-color: @color-brand-light-bg;
}

select {
	.font-body();
	background-color: @color-white;
	border-radius: 0;
	padding: @spacing-minimum @spacing-paragraph;
	border: 1px solid @color-gray-4;
	width: 100%;
	flex: 1 1 auto;
	
	option {
		.font-body();
	}
}

ul.link-rows {
	.plain-ul();

	li {
		padding: @spacing-li 0;
		transition: height 1s linear;
		
		&:not(:first-child) {
			border-top: 1px solid @color-gray-border;
		}
		
		&.collapsed {
			height: 0;
			visibility: hidden;
			padding: 0;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-decoration: none;

			.link-header {
				color: @color-brand;
				width: 140px;
				padding: 0 @spacing-paragraph;
			}

			.link-name {
				flex-grow: 1;
				text-align: left;
			}

			.link-action {
				text-align: right;
				.font-link();
				padding: 0 @spacing-paragraph;

				@media(max-width: @screen-mobile-max) {
					display: none;
				}
			}

			&:after {
				flex-grow: 0;
				flex-shrink: 0;
				.material("arrow_forward");
				margin-left: @spacing-minimum;
				.font-link();
			}
		}
	}

	&.m-wrap-rows {
		@media(max-width: @screen-mobile-max) {
			li a {
				flex-wrap: wrap;

				.link-name {
					max-width: ~"calc(100% - 140px)";
				}

				.link-action {
					font-weight: @font-weight;
					display: block;
				}

				&:after {
					display: none;
				}
			}
			//idk wtf to do here
		}
	}
}

table {
	width: 100%;
	border: none;
	border-collapse: collapse;

	@media(max-width: @screen-mobile-max) {
		display: block;

		&:not(.responsive-table) {
			overflow-x: scroll;

			tbody {
				display: table;
			}
		}

		&.responsive-table {
			tbody {
				display: block;
				border-top: 1px solid @color-gray-2;
			}

			tr {
				display: block;
				padding-top: @spacing-paragraph;

				&:first-child {
					display: none;
				}
			}

			td {
				display: flex;
				column-gap: @spacing-paragraph;
				padding: 0 0 @spacing-paragraph;
				align-items: center;

				&:before {
					content: attr(data-label);
					.font-body();
					color: @color-brand;
					font-weight: 600;
					text-transform: none;
					text-align: left;
					flex: 0 0 ~"calc(50% - 15px)";
				}
			}
		}
	}

	max-width: 100%;

	tbody {
		width: 100%;
	}

	tr {
		border: none;
		border-bottom: 1px solid @color-gray-2;

		td, th {
			border: none;
			padding: @spacing-paragraph;
		}

		th {
			padding-top: 0;
			.font-h3();
			text-transform: none;
			text-align: left;
		}
	}
}

label.action-dropdown {
	display: flex;
	align-items: center;

	*:not(:last-child) {
		margin-right: (@spacing-minimum * 2);
	}
}

.fusion-figure(@fusion-svg, @fusion-svg-width, @fusion-svg-height, @inner-img-diameter,
@img-offset-left, @img-offset-top) {
	position: relative;
	background-image: @fusion-svg;
	background-size: 100%;
	background-repeat: no-repeat;

	&:after {
		padding-bottom: @fusion-svg-height / @fusion-svg-width * 100%;
		display: block;
		content: "";
	}

	img {
		clip-path: circle(50%);
		position: absolute;
		max-width: @inner-img-diameter / @fusion-svg-width * 100%;
		left: @img-offset-left / @fusion-svg-width * 100%;
		top: @img-offset-top / @fusion-svg-height * 100%;
	}
}

figure.fusion-small {
	.fusion-figure(
	 @fusion-svg: url("/resources/img/fusion-rings-small.svg"),
	 @fusion-svg-width: 488px,
	 @fusion-svg-height: 449px,
	 @inner-img-diameter: 318px,
	 @img-offset-left: 106px,
	 @img-offset-top: 59px
	);
}

ul.triangle-list {
	.plain-ul();

	li:before {
		.material-outline("arrow_right ");
		margin-right: -1em;
		position: relative;
		top: .125em;
	}

	li.hide-bullet:before {
		visibility: hidden;
	}
}

ul.d-two-col {
	@media (min-width: @screen-tablet) {
		column-count: 2;
		column-gap: @spacing-large;
		break-inside: avoid;
	}
}

.hero-container {
	@media (min-width: @screen-laptop) {
		display: flex;
		align-items: center;
		align-content: center;
		column-gap: 40px;
	}
}

.exam-info-select-practice-test-section {
	@media (min-width: @screen-laptop) {
		display: flex;
		align-items: center;
		align-content: center;
		column-gap: 40px;
	}
	
	h2 {
		font-size: 24px;
	}
	
	.hero-highlight-box {
		@media (min-width: @screen-laptop) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-content: center;
			height: 260px;
		}
		
		p {
			font-size: 20px;
			font-weight: @font-weight-bolder;
		}
	}
}

.hero-highlight-box {
	margin-top: 8px;
	background-color: @color-white;
	border-radius: 15px;
	box-shadow: 0 11px 26px rgba(0, 0, 0, 0.15);

	padding: 45px 17px;

	@media (min-width: @screen-tablet) {
		display: inline-block;
		padding: @spacing-standard;
		margin-top: 50px;
	}

	p {
		font-family: @font-family-header;
		font-size: @font-size;
	}
	
	.box-title {
		font-weight: @font-weight-bolder;
		margin-bottom: 30px;
	}

	label {
		margin-top: @spacing-paragraph;

		select {
			flex-grow: 1;
			overflow: hidden;

			@media (min-width: @screen-tablet) {
				flex-grow: 0;
				min-width: 300px;
			}
		}
	}
	
	.pill-button {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	select {
		background: url("/resources/img/icons/71/arrow-forward-ios-rounded.svg") no-repeat 95% 50%;
		background-size: @font-size-small;
		
		-moz-appearance: none;
		-webkit-appearance: none;
		
		&::-ms-expand {
			display: none;
		}
	}
}

.blue-bleed {
	background-color: @color-blue-3;
}

.gray-bleed {
	background-color: @color-gray-2;
}

.bold {
	font-weight: @font-weight-bold;
}

.gray-4 {
	color: @color-gray-4;
}

.green-text {
	color: @color-green-text;
}

.orange-text {
	color: @color-orange-text;
}

.black-text {
	color: @color-text
}

.weight-n {
	font-weight: @font-weight;
}

.p30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.p60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.font-xs {
	font-size: 13px;
}

.font-14 {
	font-size: 14px;
	line-height: 1.4;
}

.font-size-large {
	font-size: @font-size-large;
}

.float-right {
	float: right;
}

.text-align-end {
	text-align: end;
}

.text-align-center {
	text-align: center;
}

div.imgWithPlayIcon {
	
	&::before, &::after {
		content: "";
		display: table;
		clear: both;
	}
	
	float: left;
	position: relative;
	
	img {
		width: 100%;
		height: auto;
		border: 1px solid #ddd;
	}
	
	span.playIcon {
		position: absolute;
		top: 50%;
		left: 50%;
		background: lighten(#000, 47%);
		background-color: fade(lighten(#000, 47%), 80%);
		@iconWidth: 1.2em;
		@iconHeight: 0.9em;
		width: @iconWidth;
		height: @iconHeight;
		margin-left: @iconWidth / -2;
		margin-top: @iconHeight / -2;
		
		.triangle {
			width: 0;
			height: 0;
			top: 50%;
			left: 50%;
			position: absolute;
			@triangleHeight: 0.45em;
			border-top: @triangleHeight / 2 solid transparent;
			border-bottom: @triangleHeight / 2 solid transparent;
			border-left: @triangleHeight solid white;
			margin-top: @triangleHeight / -2;
			margin-left: @triangleHeight / -2;
		}
	}
	
	&:hover {
		span.playIcon {
			background: lighten(#000, 60%);
			background-color: fade(lighten(#000, 60%), 80%);
		}
	}
}

@import "../global/components/faqs";

.faqs__faq__header {
	font-family: @font-family;
	font-weight: 600;
	line-height: @line-height-pct;
	font-size: 16px;
}

@import "../global/components/tableOfContents";

.table-of-contents__header {
	font-family: @font-family;
	font-weight: 600;
	margin-bottom: @spacing-paragraph;
	font-size: 18px;
	color: @color-text;
}
.table-of-contents__list {
	font-size: @font-size-small;
	@media(min-width: @screen-tablet){
		font-size: @font-size;
	}
}

.text-left {	text-align: left;}
.text-right {	text-align: right;}
.text-center {	text-align: center;}

.bullet() {
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 23px;
	
	::marker {
		color: @color-brand;
		font-size: 23px;
		line-height: @font-size;
		
		@media (min-width: @screen-tablet) {
			line-height: @font-size-medium;
		}
	}
}
.bullet {
	.bullet();
}

.hidden-mobile(){
	@media(max-width: @screen-mobile-max){
		display: none !important;
	}
}
.hidden-tablet(){
	@media(min-width: @screen-tablet) and (max-width: @screen-tablet-max){
		display: none !important;
	}
}
.hidden-laptop(){
	@media(min-width: @screen-laptop) and (max-width: @screen-laptop-max){
		display: none !important;
	}
}

.visible-mobile(){
	@media(max-width: @screen-mobile-max){
		display: block !important;
	}
}
.visible-tablet(){
	@media(min-width: @screen-tablet) and (max-width: @screen-tablet-max){
		display: block !important;
	}
}
.visible-laptop(){
	@media(min-width: @screen-laptop) and (max-width: @screen-laptop-max){
		display: block !important;
	}
}

.visible-mobile-inline-block(){
	@media(max-width: @screen-mobile-max){
		display: inline-block !important;
	}
}
.visible-tablet-inline-block(){
	@media(min-width: @screen-tablet) and (max-width: @screen-tablet-max){
		display: inline-block !important;
	}
}
.visible-laptop-inline-block(){
	@media(min-width: @screen-laptop) and (max-width: @screen-laptop-max){
		display: inline-block !important;
	}
}

.hidden-mobile{.hidden-mobile()}
.hidden-tablet{.hidden-tablet()}
.hidden-laptop{.hidden-laptop()}
.visible-mobile{.visible-mobile()}
.visible-tablet{.visible-tablet()}
.visible-laptop{.visible-laptop()}
.visible-mobile-inline-block{.visible-mobile-inline-block()}
.visible-tablet-inline-block{.visible-tablet-inline-block()}
.visible-laptop-inline-block{.visible-laptop-inline-block()}