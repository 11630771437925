@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";
@import (reference) "../../mixins/_icons";

@topnav-height: 54px;

:target {
	scroll-margin-top: (@topnav-height + 5px);
}

.topnav-desktop() {
	.topnav__sections {
		display: flex;
		column-gap: @spacing-paragraph;
		justify-content: flex-end;
		height: 100%;
		flex: 1;
		margin-left: @spacing-paragraph;
		max-width: (@max-width-hero - @logo-width);
		align-items: center;
		
		@media(min-width: @screen-xxl){
			column-gap: @spacing-standard;
		}
	}

	.topnav__section {
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.section__title {
			align-self: center;
			padding: @spacing-paragraph 0;

			&:focus-visible {
				outline: none;
			}

			&:hover {
				text-decoration: none;
			}

			&:after {
				.material(@material-expand-more);
				display: inline-block;
				color: @color-black;
				border: none;
				vertical-align: middle;
				margin: 0;
				position: relative;
				top: -1px;
				font-size: @font-size-small;
				font-weight: normal;
			}

			.topnav__suitename {
				display: none;

				@media (min-width: @screen-laptop) {
					display: inline;
				}
			}
			
			
			@media (min-width: @screen-laptop) {
				font-size: 14px;
				white-space: nowrap;
			}
			
			@media (min-width: @screen-xl) {
				font-size: @font-size-small;
			}
			@media (min-width: @screen-xxl) {
				font-size: @font-size;
			}
		}

		.section__body {
			display: none;
			position: absolute;
			top: 100%;
			left: -@spacing-standard;
			max-width: 600px;
			width: 600px;

			background: @color-white;
			border: 1px solid @color-gray-2;
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			box-shadow: 1px 4px 4px rgba(0, 0, 0, .25);

			li {
				padding: @spacing-paragraph/2 @spacing-standard;

				&:hover {
					background-color: @color-brand-light
				}
			}
		}

		&:hover {
			.section__body {
				display: block;

				&.section__body--state-grid {
					display: grid;
				}
			}
		}
	}
	
	.topnav__section.authentication {
		.section__title:after {
			display: none;
		}
	}

	.topnav__menu {
		display: none;
	}
}

.topnav-mobile() {
	justify-content: space-between !important;

	.topnav__sections {
		overflow: hidden;
		position: absolute;
		top: @topnav-height;
		left: 0;

		width: 100%;

		visibility: hidden;
		overflow-y: scroll;
		height: 0;
		transition: height .5s ease, visibility 0s linear .5s;

		&.show {
			visibility: visible;
			overflow-y: scroll;
			height: ~"calc(100vh - @{topnav-height})";
			transition: height .5s ease, visibility 0s linear 0s;
		}
	}

	.topnav__section {
		overflow: hidden;
		position: relative;
		border-bottom: 1px solid @color-gray-3;

		.section__title {
			.font-body();
			background-color: @color-brand-light-bg;
			padding: @spacing-standard;
			display: block;

			&:after {
				background: url("/resources/img/icons/71/arrow-forward-ios-rounded.svg") center no-repeat;
				transform: rotate(180deg);
				content: " ";
				height: 24px;
				padding: @spacing-standard;
				position: absolute;
				top: 0;
				right: 0;
			}

			&.collapsed {

				&:after {
					content: " ";
					background: url("/resources/img/icons/71/arrow-forward-ios-rounded.svg") center no-repeat;
					transform: rotate(0deg);
				}
			}
		}
		
		&.authentication {
			text-align: center;
			
			&.section__title {
				margin-bottom: 2px;
			}
			
			.section__title:after {
				display: none;
			}
		}

		.section__body {
			max-height: 0;
			transition: max-height .5s ease-out;
			background: #fff;
			&.show {
				max-height: 500px;
				transition: max-height .5s ease-in;
			}

			li {
				padding: 10px 0;
				margin: 0 @spacing-standard;

				&:not(:last-child) {
					border-bottom: 1px solid @color-gray-3;
				}
				
			}

			&.section__body--state-grid {
				li {
					border-bottom: none !important;
				}
			}
		}
	}
}

#my-account-nav {
	span {
		@media (min-width: @screen-tablet) {
			display: none;
		}
	}
	
	&:before {
		font-size: @font-size-larger;
		.material(@material-profile);
		vertical-align: text-bottom;
		
		@media (max-width: @screen-tablet) {
			margin-right: 5px;
		}
	}
}

.topnav {
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	left: 0;
	padding: 15px;
	height: auto;
	background: @color-brand-light-bg;
	@media (min-width: @screen-max-hero) {
		padding-left: ~"calc((100vw - @{max-width-hero}) / 2)";
	}

	width: 100%;
	z-index: 100;

	ul {
		.plain-ul();
	}

	.section__body {
		a {
			font-weight: @font-weight;
			display: block;
		}

		&.section__body--state-grid {
			display: grid;

			grid-template-columns: repeat(auto-fill,minmax(2em,1fr));

			&.show {
				padding: 10px;
			}

			@media (min-width: @screen-laptop) {
				width: 400px !important;
				padding: 10px;
			}

			column-gap: 15px;
			row-gap: 10px;

			li {
				margin: 0 !important;
				padding-left: 0 !important;
				padding-right: 0 !important;
				text-align: center;
			}
		}
	}

	
	
	.topnav__menu {
		font-weight: normal;
		color: @color-brand;
		text-decoration: none;
		
		&:after {
			.material("close");
			display: block;
			font-size: 40px;
		}

		&.collapsed:after {
			.material("menu");
		}

	}

	@media(max-width: (@screen-laptop - 1)) {
		.topnav-mobile();
		
		&.topnav--shop {
			@media(max-width: @screen-mobile-max) {
				box-shadow: none;
			}
			
			.logo {
				@media(max-width: @screen-xs-max) {
					font-size: 16px;
					width: 210px;
				}
				@media(max-width: (@screen-xs - 1)) {
					font-size: 14px;
					width: 180px;
				}
			}
			
			.topnav__sections {
				display: flex;
				column-gap: 15px;
				justify-content: flex-end;
				height: 100%;
				flex: 1;
				margin-left: 15px;
				max-width: 965px;
				visibility: visible;
				position: relative;
				top: auto;
				left: auto;
				overflow: auto;
				width: auto;
				
				.topnav__section {
					border-bottom: none;
				}
			}
			
			#sign-up-button {
				width: auto;
			}
		}
	}

	@media (min-width: @screen-laptop) {
		.topnav-desktop();
	}
	
	#sign-up-button {
		@media (min-width: @screen-laptop) {
			
			font-size: 14px;
			padding: 10px 20px;
		}
		@media(min-width: @screen-xl){
			font-size: @font-size-small
		}
		@media(min-width: @screen-xxl){
			font-size: @font-size;
			padding: 20px 40px;
		}
	}
		
		
		a {
		color: @color-black;
	}
}

.topnav-scrolled {
	background-color: @color-brand-light-bg !important;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

#sign-up-button {
/*	font-size: @font-size-small;
	font-weight: @font-weight;
	line-height: 12px;
	width: fit-content;
	width: fit-content;
	height: 40px;
	padding: @spacing-paragraph;
	margin: 0;
	white-space: nowrap;
	border-radius: 20px;
	
	@media (max-width: (@screen-laptop - 1)) {
		margin: @spacing-paragraph auto;
		background-color: @color-button;
		color: @color-white;
		width: 90%;
	}*/
	
	.pill-button();
	height: auto;
	margin: 0;
}

.logo {
	display: inline-block;
	text-decoration: none !important;
	.font-h3();
	
	color: #000;
	font-weight: @font-weight-bold;
	text-transform: none;
	@media (min-width: @screen-tablet) {
		font-size: @font-size;
	}
	@media(min-width: @screen-laptop) {
		font-size: @font-size-medium;
	}
	@media(min-width: @screen-xl) {
		font-size: @font-size-mediumer;
	}
	.logo-color {
		color: @color-brand;
	}
	
	width: @logo-width;
}