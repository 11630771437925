@import (reference) "../_variables.less";
@import (reference) "../../global/global-template.less";

#sign-up-button {
	background-color: @color-button;
	color: @color-white;
	font-size: 20px;
	line-height: 20px;
	display: flex;
	align-items: center;
}

nav.breadcrumbs {
	background-color: @color-white;
}

header.hero.with-cta {
	section.content {
		display: flex;
		align-items: center;
		
		@media(max-width: (@screen-laptop - 1px)) {
			flex-direction: column;
			row-gap: 30px;
			
			.hero-cta {
				flex: auto;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	
	h1 {
		text-align: left;
		flex: 1 1 100%;
	}
}

.hero-cta {
	flex: 0 0 430px;
	text-align: left;
	border-radius: 6px;
	border: 1px solid @color-highlight;
	background-color: @color-white;
	padding: 30px;
	margin-right: 30px;
}

.hero-cta__title {
	font-size: 24px;
	line-height: 31px;
	font-weight: @font-weight-bold;
	font-family: @font-family-header;
	color: @color-brand;
}

.hero-cta__prompt {
	font-size: 16px;
	line-height: 22.4px;
	font-family: @font-family-header;
	padding-top: 15px;
}

.hero-cta__select {
	width: 100%;
	font-size: 16px;
}

.hero-cta__button {
	padding-top: 15px;
}

.hero-cta__create-account {
	display: inline-block;
	background-color: @color-brand;
	color: @color-white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-size: 24px;
	line-height: 33.6px;
	font-family: @font-family-header;
	font-weight: @font-weight-semibold;
	padding: 13px 60px;
}

.tcc-cta-wrapper {
	background-color: @color-gray-e;
	margin-top: 60px;
	margin-bottom: 60px;
}

.tcc-cta {
	.width-and-gutters();
	
	display: flex;
	gap: 30px;
}

.tcc-cta__left {
	flex: 1 1 100%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.tcc-cta__right {
	flex: 0 0 450px;
	align-self: center;
	display: flex;
	position: relative;
	
	img {
		width: 100%;
	}
	
	@media(max-width: @screen-mobile-max) {
		display: none;
	}

	@media(max-width: (@screen-laptop - 1px)) {
		flex-basis: 300px;
	}
}

.tcc-cta--stacking {
	@media(max-width: @screen-mobile-max) {
		flex-direction: column;
	}
	
	@media(max-width: @screen-mobile-max) {
		gap: 15px;
	}
	
	
	.tcc-cta__left {
		@media(max-width: @screen-mobile-max) {
			padding-bottom: 0;
			
			.tcc-cta__cta {
				display: none;
			}
		}
	}
	
	.tcc-cta__right {
		flex-basis: 585px;
		display: block;
		width: 100%;
		
		@media(max-width: (@screen-laptop - 1px)) {
			flex-basis: 320px;
		}

		@media(max-width: @screen-mobile-max) {
			display: block;
			flex-basis: 100%;
			padding-bottom: 30px;
		}
		
		.tcc-cta__cta {
			@media(min-width: @screen-tablet) {
				display: none;
			}
		}
	}
}

.tcc-cta__icon-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: start;
	flex-wrap: wrap;
	row-gap: 30px;
	
	@media(min-width: @screen-tablet) and (max-width: (@screen-laptop - 1px)) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	@media(min-width: @screen-tablet) {
		list-style: none;
		padding-inline-start: 0;
	}

	@media(max-width: @screen-mobile-max) {
		display: block;
	}
}

.tcc-cta__icon-with-label {
	flex: 0 0 25%;
	text-align: center;
	
	@media(min-width: @screen-tablet) and (max-width: (@screen-laptop - 1px)) {
		flex: 0 0 50%;
	}

	@media(max-width: @screen-mobile-max) {
		text-align: left;
	}
}

.tcc-cta__icon {
	@media(max-width: @screen-mobile-max) {
		display: none;
	}
	
	img {
		height: 60px;
		width: auto;
		
		@media(max-width: (@screen-laptop - 1px)) {
			height: 48px;
		}
	}
	
	padding-bottom: 10px;
}

.tcc-cta__icon-stat {
	font-size: 26px;
	font-weight: @font-weight-bold;
	padding-top: 10px;
	
	@media(min-width: @screen-tablet) and (max-width: (@screen-laptop - 1px)) {
		padding-top: 0;
	}
	
	@media(max-width: (@screen-laptop - 1px)) {
		font-size: 18px;
	}

	@media(max-width: @screen-mobile-max) {
		display: inline-block;
		font-size: 16px;
	}
}

.tcc-cta__icon-label {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: @font-weight-semibold;
	padding-top: 10px;
	max-width: 100px;
	margin: auto;
	
	@media(max-width: @screen-mobile-max) {
		display: inline-block;
		max-width: none;
		text-transform: none;
	}
	
	@media(min-width: @screen-tablet) and (max-width: (@screen-laptop - 1px)) {
		padding-top: 0;
	}
}

.tcc-cta__testimonial {
	padding: 10px 0;
	
	&:not(:last-child) {
		border-bottom: 1px solid @color-gray-3;
	}
}

.tcc-cta__testimonial-metadata {
	text-transform: uppercase;
	font-size: 14px;
}

.tcc-cta__lesson-image {
	border: 2px solid @color-orange-text;
}

.tcc-cta__lesson-image-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	background-color: @color-orange-text;
	color: @color-white;
	text-align: center;
	font-family: @font-family-header;
	font-size: 12px;
	font-weight: @font-weight-bold;
	padding: 3px 0;
}

.tcc-cta__right--pin-to-bottom {
	align-self: end;
	
	img {
		position: relative;
		bottom: 1px;
	}
}

.tcc-cta__logo {
	font-family: @font-family-header;
	font-size: 18px;
	font-weight: @font-weight-bold;
	color: @color-gray-6;
}

.tcc-cta__logo-blue {
	color: @color-brand;
}

.tcc-cta__header {
	font-family: @font-family-header;
	font-size: 26px;
	line-height: 34px;
	font-weight: @font-weight-bold;
	color: @color-brand;
	padding: 15px 0;
	
	@media (max-width: (@screen-laptop - 1px)) {
		font-size: 18px;
		line-height: 25px;
	}
}

.tcc-cta__text {

}

.tcc-cta__bullets {
	padding-top: 15px;
}

.tcc-cta__cta {
	padding-top: 30px;
}

.tcc-cta__create-account {
	display: inline-block;
	background-color: @color-button;
	color: @color-white;
	padding: 18px 30px;
	font-size: 24px;
	line-height: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	
	@media (max-width: @screen-mobile-max) {
		width: 100%;
		text-align: center;
	}
}

.cta-footer {
	background: url("/resources/img/hero text with background.svg") no-repeat center / cover;
	background-color: @color-gray-e;
	padding: 60px;
	margin: 0;
	
	.tcc-cta__header {
		text-align: center;
	}
	
	.tcc-cta__cta {
		text-align: center;
	}
}

.practice-test-results-cta {
	width: 100%;
	
	.full-width-cta {
		width: 100%;
		position: initial;
		margin: 30px 0 0;
	}
	
	.tcc-cta__logo {
		display: none;
	}
	
	.tcc-cta__cta {
		display: none;
	}
	
	.stats-section__cards {
		@media(min-width: @screen-tablet){
			flex-wrap: wrap;
		}
	}
}
.individual-test .exam-container aside.practice-test-results-sign-up-button {
	display: none;
}



.individual-test .exam-container .results-display .practice-test-results-cta {
	@media(min-width: @screen-tablet){
		text-align: left;
	}
}
.practice-test-results-sign-up-button {display: none;}

.cta-video-lessons-small {

	h2 {
		text-align: center;
	}
	
	.cta-button-container {
		text-align: center;
		margin-top: 30px;
		
		@media (min-width: @screen-laptop) {
			margin-top: 60px;
		}
	}
}

.white-bleed.image-and-detail__bleed-tablet {
	background: url(/resources/img/71/tablet-score.png) -230px 390px @color-brand-light-bg no-repeat;
	background-size: 680px;
	
	
	@media(min-width:@screen-tablet) {
		background: url(/resources/img/71/tablet-score.png) 200px bottom #fff no-repeat;
		background-size: 700px;
		padding-bottom: 50px;
	}
	
	@media(min-width:@screen-laptop) {
		background: url(/resources/img/71/tablet-score.png) 270px bottom #fff no-repeat;
		background-size: 1000px;
	}
	
	@media(min-width:@screen-xl) {
		background: url(/resources/img/71/tablet-score.png) right -100px bottom #fff no-repeat;
		background-size: 1000px;
	}
	@media(min-width:@screen-xxl) {
		background: url(/resources/img/71/tablet-score.png) right -100px bottom no-repeat #fff;
		background-size: 1210px;
	}
	@media(min-width: 1700px){
		background: url(/resources/img/71/tablet-score.png) right bottom/cover no-repeat #fff;
		background-size: auto;
	}
	
	.image-and-detail {
		@media (min-width: @screen-tablet) {
			margin-bottom: 0;
		}
		@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max){
			flex-direction: column;
		}
	}
	.image-and-detail__image-container {
		height: 180px;
	}
}
