@import "../global/global-variables";

@color-brand: #F48120;
@color-link: @color-brand;
@color-brand-light: #85B7D3;
@color-brand-light-bg: #FEF8F2;
@color-brand-gray-bg: #FAFAFA;
@color-brand-dark: #3F5D82;
@color-brand-darker: #0F3159;
@color-brand-secondary-light: #A1CBE2;
@color-highlight: #E8A93C;
@color-orange-text: #C76411;
@color-error: #A94442;
@color-green-text: #538E28;
@color-gray-6: #1A1C1E;
@color-text: #000000;
@color-header: #000000;
@color-gray-border: #D9D9D9;
@color-gray-select: #B6B6B6;
@color-gray-5: #555555;
@color-gray-4: #777777;
@color-gray-3: #B9B9B9;
@color-gray-2: #E1E1E1;
@color-gray-1: #F8F8F8;
@color-gray-e: #EEEEEE;
@color-button: @color-brand;
@color-button-light: #ff933b;
@color-white: #FFFFFF;
@color-black: #222222;
@color-blue-3: #6A98B1;
@color-blue-lightest: #ADD5EC;
@color-practice-exam-success: @color-button;

@screen-xs: 375px;
@screen-xs-max: 575px;
@screen-mobile: 576px;
@screen-mobile-max: (768px - 1);
@screen-tablet: 768px;
@screen-tablet-max: (@screen-laptop - 1);
@screen-laptop: 992px;
@screen-laptop-max: (@screen-xl - 1);
@screen-max-content: (@max-width-content + @spacing-standard * 2);
@screen-max-hero: (@max-width-hero + @spacing-standard * 2);
@screen-xl: 1200px;
@screen-desktop: @screen-xl;
@screen-xxl: 1400px;

@spacing-large: 60px;
@spacing-li: 30px;
@spacing-standard: 30px;
@spacing-paragraph: 15px;
@spacing-minimum: 5px;

@font-import: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400&display=swap';

@font-family: 'Montserrat', sans-serif;
@font-family-header: 'Montserrat', sans-serif;

@font-weight-bolder: 700;
@font-weight-bold: 600;
@font-weight-semibold: 500;
@font-weight: 400;

@font-size-tiny: 13px;
@font-size-small: 16px;
@font-size: 18px;
@font-size-medium: 20px;
@font-size-mediumer: 24px;
@font-size-large: 25px;
@font-size-larger: 30px;
@font-size-largerer: 48px;
@font-size-larger-not-huge: 30px;
@font-size-huge: 60px;

@max-width-content: 1200px;
@max-width-hero: 1400px;

@letter-spacing-ssp: 0.1px;
@line-height-pct: 150%;
@line-height-pct-medium: 135%;
@line-height-pct-small: 122%;

@link-arrow-icon: "trending_flat";
@link-arrow-font-size: 28px;
@logo-width: 311px;


//smallerize test vars
@font-size-48: 48px; //h1
@font-size-34: 34px; //h1 tab
@font-size-24: 24px; //h1 mob

@font-size-36: 36px; //h2
@font-size-22: 22px; //h2 mob

@font-size-20: 20px; //h3 mob

@font-size-28: 28px; //cta header
@font-size-26: 26px; //cta header
@font-size-18: 18px; //cta button
@font-size-16: 16px; //footer links
@font-size-14: 14px; //body
@font-size-12: 12px;
@section-spacing-30: 30px;
@section-spacing-15: 15px;
@section-spacing-8: 8px;



.font-body(@font-family-var: @font-family, @font-weight-var: @font-weight) {
	font-family: @font-family-var;
	font-weight: @font-weight-var;
	font-size: @font-size-small;
	line-height: @line-height-pct;
	color: @color-text;
	letter-spacing: @letter-spacing-ssp;
	
	@media (min-width: @screen-tablet) {
		font-size: @font-size;
	}
}

.font-h3(@desktop-size: @font-size-medium, @font-weight-var: @font-weight-bolder, @font-family-var: @font-family-header) {
	.font-body();
	color: @color-header;
	font-weight: @font-weight-var;
	font-family: @font-family-var;
	line-height: @line-height-pct-medium;
	letter-spacing: 0;
	
	@media (min-width: @screen-tablet) {
		font-size: @desktop-size;
	}
}

.font-h2(@mobile-size: @font-size-larger; @desktop-size: @font-size-largerer; @font-weight-var: @font-weight-bolder; @font-family-var: @font-family-header) {
	.font-h3();
	line-height: @line-height-pct-small;
	font-size: @mobile-size;
	font-weight: @font-weight-var;
	font-family: @font-family-var;

	@media (min-width: @screen-tablet) {
		font-size: @desktop-size;
	}
}

.font-h1(@mobile-size: @font-size-larger; @desktop-size: @font-size-huge; @font-weight-var: @font-weight-bolder; @font-family-var: @font-family-header) {
	.font-h2();

	text-align: left;
	font-size: @mobile-size;
	font-weight: @font-weight-var;
	font-family: @font-family-var;

	@media (min-width: @screen-tablet) {
		font-size: @desktop-size;
	}
}

.font-sizes(@mobile-size: null; @tablet-size: null; @desktop-size: null) {
	& when not(@mobile-size = null) {
		font-size: @mobile-size;
	}
	
	& when not(@tablet-size = null) {
		@media (min-width: @screen-tablet) {
			font-size: @tablet-size;
		}
	}
	
	& when not(@desktop-size = null) {
		@media (min-width: @screen-laptop) {
			font-size: @desktop-size;
		}
	}
}

.section-spacing(
	@padding-top-mobile: null;
	@padding-bottom-mobile: null;
	@padding-top-tablet: null;
	@padding-bottom-tablet: null;
	@padding-top-desktop: null;
	@padding-bottom-desktop: null;
	@margin-top-mobile: null;
	@margin-bottom-mobile: null;
	@margin-top-tablet: null;
	@margin-bottom-tablet: null;
	@margin-top-desktop: null;
	@margin-bottom-desktop: null;

) {
	& when not(@padding-top-mobile = null) {
		padding-top: @padding-top-mobile;
	}
	& when not(@padding-bottom-mobile = null) {
		padding-bottom: @padding-bottom-mobile;
	}
	& when not(@margin-top-mobile = null) {
		margin-top: @margin-top-mobile;
	}
	& when not(@margin-bottom-mobile = null) {
		margin-bottom: @margin-bottom-mobile;
	}
	
	
	& when not(@padding-top-tablet = null) {
		@media (min-width: @screen-tablet) {
			padding-top: @padding-top-tablet;
		}
	}
	& when not(@padding-bottom-tablet = null) {
		@media (min-width: @screen-tablet) {
			padding-bottom: @padding-bottom-tablet;
		}
	}
	& when not(@margin-top-tablet = null) {
		@media (min-width: @screen-tablet) {
			margin-top: @margin-top-tablet;
		}
	}
	& when not(@margin-bottom-tablet = null) {
		@media (min-width: @screen-tablet) {
			margin-bottom: @margin-bottom-tablet;
		}
	}
	
	
	& when not(@padding-top-desktop = null) {
		@media (min-width: @screen-laptop) {
			padding-top: @padding-top-desktop;
		}
	}
	& when not(@margin-bottom-desktop = null) {
		@media (min-width: @screen-laptop) {
			margin-bottom: @margin-bottom-desktop;
		}
	}
	& when not(@padding-bottom-desktop = null) {
		@media (min-width: @screen-laptop) {
			padding-bottom: @padding-bottom-desktop;
		}
	}
	& when not(@margin-top-desktop = null) {
		@media (min-width: @screen-laptop) {
			margin-top: @margin-top-desktop;
		}
	}
}

.hero-bg() {
	background-color: @color-brand-light-bg;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&:not(.arc-background)&:not(.about-us-background) {
		background-image: none;
		
		background-size: 253px;
		
		@media (min-width: @screen-tablet) {
			background-size: 457px;
		}
	}
}

.font-link() {
	color: @color-link;
	font-weight: @font-weight-bold;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

@material-expand-more: '\e5cf';
@material-expand-less: '\e5ce';
@material-arrow-right: '\e5df';
@material-arrow-forward: '\e5c8';
@material-profile: '\e7fd';
@material-check-mark: '\2713';
@material-chevron-forward: '\e5e1';
@border-radius-large: 6px;
